html,
body {
    padding: 0;
    margin: 0;
    font-family: 'IBM Plex Sans', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
    Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-size: 16px;
    background: #ffffff;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

#PolarisSelect1{
    outline:none;
    border: none;
    background: #ffffff;
    height: 50px;
}

.Polaris-Select:not(.Polaris-Select--newDesignLanguage) .Polaris-Select__Backdrop{
    border: none;
    box-shadow: none;
}

.Polaris-Select .Polaris-Select__Content{
    min-height: 50px;
    font-weight: 600;
    font-family: 'Work Sans', sans-serif;
    font-size: 1rem;
    padding: 0.25rem 0.5rem 0.25rem 1.25rem;
}
